<template>
  <div class="noscrollmain">
    <common-top
      title="单位发文"
      :isShowSearch="isShowSearch"
      @search="showModal = true"
    ></common-top>

    <Modal v-model="showDetailStatus" fullscreen>
      <p slot="header" style="text-align: center">
        <span>{{ detailInfo["单位"] }}</span>
      </p>

      <div class="commonShadow">
        <list-com
          :columns="columnsContent"
          :title="title"
          :showList="dataContentList"
          :titleSelfLine="titleSelfLine"
          :titleSelfTitle="titleSelfTitle"
          :descWidth="true"
          :showLoad="showLoad"
        ></list-com>
      </div>

      <div slot="footer">
        <Button size="large" long @click="showDetailStatus = false"
          >确定</Button
        >
      </div>
    </Modal>

    <dept-choose-com
      :defaultEndDate="endDate"
      :defaultStartDate="startDate"
      :showModal="showModal"
      @chooseDept="chooseDept"
      :isShowDept="isShowDept"
      @cancel="showModal = false"
      :chooseDate="true"
      :isShowTime="true"
    ></dept-choose-com>

    <task-load-com
      :postData="postData"
      :postUrl="postUrl"
      @initData="initData"
      :showTaskLoad="showTaskLoad"
      v-if="showTaskLoad"
    ></task-load-com>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-show="showList && isUserPm">
      <desc-com
        :title="descTitle"
        :startDate="startDate"
        :endDate="endDate"
        :isFilter="true"
        :sortList="sortList"
        @sortData="sortData"
      ></desc-com>
      <list-com
        @chooseListItem="chooseListItem"
        :columns="columns"
        :title="title"
        :descWidth="true"
        :showList="dataList"
        :showLoad="showLoad"
      ></list-com>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";
import moment from "moment";
import {
  wxConPost,
  getUserPm,
  getRadio,
  getOptBar,
  getRadioDate,
} from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listCom from "../../components/listCom.vue";
import deptChooseCom from "../../components/deptLevelChooseCom.vue";
import descCom from "../../components/descComAndSort.vue";
import noPassCom from "../../components/noPassCom.vue";

import huanBiTongBiCom from "../../components/huanBiTongBiCom.vue";
import taskLoadCom from "../../components/taskLoadCom.vue";

export default {
  name: "deptContentNew",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,
    deptChooseCom,
    descCom,
    noPassCom,
    huanBiTongBiCom,
    taskLoadCom,
  },

  data() {
    return {
      postData: {},
      postUrl: "/wztj/deptFwTj",
      showTaskLoad: false,

      optionHuanBiRadio: {},
      optionTongBiRadio: {},
      optionUserJiefenAll: {},

      titleSelfTitle: "title",
      titleSelfLine: true,

      descTitle: "", // 描述说明
      isShowSearch: false, // 是否显示搜索框
      isShowDept: false, // 超管部门选择
      isUserPm: true, // 是否有权限

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      tjLevel: 2, // 部门层级
      startDate: "2022-01-01",
      endDate: "2022-02-20",

      showDetailStatus: false,
      detailInfo: {}, // 单位详情
      title: "单位",
      columns: [
        {
          name: "单位发文",
          value: "pubContent",
          color: "#866060",
        },
        {
          name: "阅读量",
          value: "count",
        },
        {
          name: "点赞量",
          value: "like",
        },
        {
          name: "评论量",
          value: "comment",
        },
        {
          name: "分享量",
          value: "share",
        },

        {
          name: "外部阅读",
          value: "count_out",
          color: "#666",
        },
        {
          name: "阅读人数",
          value: "perCount",
        },
        {
          name: "阅读率",
          value: "countRate",
        },
      ],
      // sortList: ["单位运动", "人均运动", "环比增长"],

      sortList: [
        // 默认降序 desc  可选升序 asc
        { name: "单位发文", value: "pubContent", order: "desc" },
        { name: "阅读量", value: "count", order: "desc" },
        { name: "阅读人数", value: "inPerCount", order: "desc" },
        { name: "点赞量", value: "like", order: "desc" },
        { name: "分享量", value: "share", order: "desc" },
        { name: "留言量", value: "comment", order: "desc" },
        { name: "外部阅读", value: "outCount", order: "desc" },
        { name: "阅读率", value: "countRateNum", order: "desc" },

        // { name: "环比增长", value: "环比", order: "desc" },
      ],
      // 单位、发文数、 浏览量、 点赞量、 分享量、 留言量、 外单位阅读量
      showList: true,
      deptData: [],
      dataList: [],
      ratioData: [],

      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      dataNumOption: {},

      deptInfo: {},
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      dataTimer: null,
      radioTimer: null,

      columnsContent: [
        {
          name: "阅读量",
          value: "count",
        },
        {
          name: "点赞量",
          value: "like",
        },
        {
          name: "评论量",
          value: "comment",
        },
        {
          name: "分享量",
          value: "share",
        },

        {
          name: "阅读率",
          value: "countRate",
        },

        {
          name: "外部阅读",
          value: "outCount",
          color: "#666",
        },
      ],
      dataContentList: [],
      deptInfo: [],
    };
  },
  methods: {
    // 数据任务组件
    initData(data) {
      this.showTaskLoad = false; // 销毁组件，为下次再次查询

      if (!_.isUndefined(data)) {
        const deptData = data.ret ? data.ret : [];
        this.dealData(deptData);
      } else {
        this.dealData([]);
      }
    },
    // 数据任务组件初始化
    initTaskCom() {
      let _query = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
      if (this.deptIdArr.length > 0) {
        _query["deptIdArr"] = this.deptIdArr;
        _query["tjLevel"] = this.tjLevel;
      }
      this.postData = _query;
      console.log("----------", this.postData, this.showTaskLoad);

      this.showTaskLoad = true;
    },

    // 选择部门
    chooseDept(emitData) {
      this.tjLevel = emitData.level;
      this.deptIdArr = emitData.deptIdArr;
      this.startDate = emitData.startDate;
      this.endDate = emitData.endDate;

      this.showModal = false;
      this.initTaskCom();
    },
    clearTimmerFun() {
      if (this.dataTimer) {
        clearTimeout(this.dataTimer);
      }
      if (this.radioTimer) {
        clearTimeout(this.radioTimer);
      }
    },

    // 处理数据
    dealData(data) {
      let dataList = [];

      // comment
      // count
      // count_out
      // deptName
      // like
      // perCount
      // pubContent
      // share
      // userStatusCount
      // ycContent
      // _cidArr
      for (const iterator of data) {
        // 阅读率
        let countRate = 0;
        if (iterator.userStatusCount && iterator.pubContent) {
          countRate =
            (iterator.perCount /
              iterator.userStatusCount /
              iterator.pubContent) *
            100;
          if (countRate > 100) {
            countRate = 100;
          }
        }

        dataList.push(
          Object.assign(iterator, {
            单位: iterator.deptName,
            本期数: iterator.pubContent,
            countRateNum: countRate,
            countRate: `${countRate.toFixed(2)}%`,
          })
        );
      }
      this.dataList = _.reverse(_.sortBy(dataList, ["count"]));
    },

    // 显示详情
    async chooseListItem(item) {
      this.detailInfo = item;

      // this.optionHuanBiRadio = getOptBar(
      //   "发文环比趋势图",
      //   "发文量",
      //   [
      //     this.detailInfo["上期数"].toFixed(0),
      //     this.detailInfo["本期数"].toFixed(0),
      //   ],
      //   ["上期数", "本期数"]
      // );

      // this.optionTongBiRadio = getOptBar(
      //   "发文同比趋势图",
      //   "发文量",
      //   [
      //     this.detailInfo["同期数"].toFixed(0),
      //     this.detailInfo["本期数"].toFixed(0),
      //   ],
      //   ["同期数", "本期数"]
      // );

      this.showDetailStatus = true;
      this.getContentList(_.uniq(item._cidArr));

      // this.dealDeptPunContentPieChartData(this.detailInfo._id);
    },

    async dealDeptPunContentPieChartData(deptId) {
      // 查询数据
      const ret = await wxConPost(
        "/services/gttt-wxstats-mgt/rankContent/deptDatePubContent",
        {
          startDate: this.startDate,
          endDate: this.endDate,
          deptId,
        }
      );
      const data = ret.ret;

      const chartXAxis = _.map(data, "_id");
      const chartData = _.map(data, "pubContent");

      this.optionUserJiefenAll = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: chartXAxis,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },

        series: [
          {
            name: "发文",
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            type: "line",
            showBackground: true,
            data: chartData,
          },
        ],
      };

      this.$nextTick(() => {
        this.$refs.userJifenAll.refreshData();
      });
    },

    async getContentList(cidArr) {
      const ret = await wxConPost(
        "/services/gttt-wxstats-mgt/rankContent/getContentList",
        { cidArr }
      );
      if (this.deptInfo.length <= 0) {
        await this.getDeptList();
      }
      // console.log("------,", ret);
      const dataContentList = _.get(ret, ["ret"], []);
      let _data = [];

      for (const iterator of dataContentList) {
        if (iterator["deptId3_content"]) {
          let userStatusCount = 0;
          let deptInfo = null;

          if (
            this.deptInfo[iterator.deptId3_content] ||
            this.deptInfo[`${iterator.deptId3_content}`]
          ) {
            userStatusCount =
              this.deptInfo[iterator.deptId3_content]["userStatusCount"];
            deptInfo = this.deptInfo[iterator.deptId3_content];
          }

          if (deptInfo) {
            let count = (iterator.inCount || 0) + (iterator.outCount || 0);
            let like = (iterator.inLike || 0) + (iterator.outLike || 0);
            let comment =
              (iterator.inComment || 0) + (iterator.outComment || 0);
            let share = (iterator.inShare || 0) + (iterator.outShare || 0);

            // 阅读率
            let countRate = 0;
            if (iterator.inPerCount > 0 && userStatusCount) {
              countRate = (iterator.inPerCount / userStatusCount) * 100;
            }

            // 点赞率
            let likeRate = 0;
            if (iterator.inPerCount > 0 && userStatusCount) {
              likeRate = (iterator.inLike / userStatusCount) * 100;
            }
            // 分享率
            let shareRate = 0;
            if (iterator.inPerCount > 0 && userStatusCount) {
              shareRate = (iterator.inShare / userStatusCount) * 100;
            }
            // 分享率
            let commentRate = 0;
            if (iterator.inPerCount > 0 && userStatusCount) {
              commentRate = (iterator.inComment / userStatusCount) * 100;
            }

            _data.push(
              Object.assign(iterator, {
                单位: deptInfo.dept_2nd_name,
                count,
                like,
                comment,
                share,
                countRate: `${countRate.toFixed(2)}%`,
                likeRate: `${likeRate.toFixed(2)}%`,
                shareRate: `${shareRate.toFixed(2)}%`,
                commentRate: `${commentRate.toFixed(2)}%`,
              })
            );
          }
        }
      }

      _data = _.reverse(_.sortBy(_data, ["count"]));
      this.dataContentList = _data;
    },

    async getDeptList() {
      try {
        const ret = await wxConPost(
          "/services/gttt-wxstats-mgt/user/getDeptList",
          {}
        );
        this.deptInfo = _.get(ret, ["ret"], {});
        console.log("00000----", this.deptInfo);
      } catch (error) {
        console.log("----", error);
      }
    },

    // 数据排序
    sortData(data) {
      if (data && data.value) {
        if (data.order == "aes") {
          // 降序
          this.dataList = _.sortBy(this.dataList, [data.value]);
        } else {
          // 升序
          this.dataList = _.reverse(_.sortBy(this.dataList, [data.value]));
        }

        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    },

    /******************** 环比/同比 */
    async commonRatioDataFun(query, type) {
      this.clearTimmerFun();

      try {
        let _query = query;
        //  计算周期
        let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
        if (type == "tongbi") {
          const dateObj = getYearOnYearBasisDate(this.startDate, this.endDate);
          startDate = dateObj.startDate;
          endDate = dateObj.endDate;
        }

        _query["startDate"] = startDate;
        _query["endDate"] = endDate;

        const ret = await wxConPost(
          "/services/gttt-wxstats-mgt/rankContent/deptContentList",
          _query
        );

        return _.get(ret, ["ret"], []);
      } catch (error) {
        return [];
      }
    },

    dealCommonRadioData(data, type, typeVal) {
      for (const iterator of this.dataList) {
        // 计算环比差

        const _huanBiData = _.find(data, (o) => {
          return o._id == iterator._id;
        });

        let pubContent = _.get(_huanBiData, ["pubContent"], 0);
        // 环比增长率=（本期数-上期数）/上期数×100%
        iterator[type] = getRadio(iterator["pubContent"], pubContent);
        iterator[typeVal] = pubContent;
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },

    async getRadioAndYearOnYearData(query) {
      try {
        const huanbiData = await this.commonRatioDataFun(query);
        const tongbiData = await this.commonRatioDataFun(query, "tongbi");

        this.dealCommonRadioData(huanbiData, "环比增长", "上期数");
        this.dealCommonRadioData(tongbiData, "同比增长", "同期数");
      } catch (error) {
        console.log("------", error);
      }
    },
  },
  destroyed() {
    this.clearTimmerFun();
  },
  async created() {
    this.startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    this.endDate = moment().subtract(1, "days").format("YYYY-MM-DD");

    const userPm = getUserPm();
    if (userPm && userPm.statsPmStatus) {
      this.tjLevel = userPm.datalevel;
      this.deptIdArr = JSON.parse(localStorage.getItem("pmDeptIdArr"));

      this.isShowSearch = true;
      this.isShowDept = true;
      this.initTaskCom();

      this.getDeptList();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped>
.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}
</style>
